<template>
  <div class="market">
    <head-nav></head-nav>
    <div class="market-banner">
      <img
        src="@/assets/image/market-analysis/market-analysis.png"
        alt=""
      />
    </div>
    <div class="market-box1">
      <div class="wrap">
        <div class="market-top">
          <h3 class="market-top-title">
            <img
              :src="$t('market-analysis.title-1')"
              alt=""
            />
          </h3>
          <div class="market-top-img">
            <div
              class="market-top-imgs"
              v-for="(item, index) in $t('market-analysis.list-info')"
              :key="index"
            >
              <h4>{{ item.title }}</h4>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="market-box2">
      <div class="wrap">
        <h3 class="market-title">
          <img
            :src="$t('market-analysis.title-1')"
            alt=""
          />
        </h3>

        <div class="market-img">
          <div
            class="market-imgs"
          >
            <img
              :src="$t('market-analysis.list-img')"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>

import HeadNav from './head-nav';

import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.market {
  .market-banner {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .market-box1 {
    background: #eee;

    .market-top {
      padding: 90px 0 80px 0;

      .market-top-title {
        text-align: center;
        margin-bottom: 30px;
      }

      .market-top-img {
        margin-top: 30px;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;

        .market-top-imgs {
          width: 49%;
          margin-top: 20px;

          h4 {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
          }

          p {
            margin: 30px auto 0 auto;
            line-height: 24px;
            color: #555555;
            font-size: 14px;
            width: 370px;
          }
        }

        .market-top-imgs:nth-child(1) {
          width: 50%;
          border-right: 1px solid #dddddd;
        }
      }
    }
  }

  .market-box2 {
    width: 100%;

    .wrap {
      padding: 90px 0;

      .market-title {
        text-align: center;
      }

      .market-img {
        margin-top: 40px;

        .market-imgs {
          text-align: center;
          margin-top: 80px;

          img {
            width: 780px;
            height: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .market .market-banner,
  .market .market-box1,
  .market .market-box1 {
    width: 1200px;
  }
  .throughput .throughput-box1 .wrap .throughput-img .throughput-imgs {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
